import React, { FC, useCallback } from 'react'
import { Icon } from '@iconify/react'
import { getBroadcastsFn } from 'api'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { LinkTo } from 'components/atoms/Pointer'
import Button from 'components/molecules/Button'
import { LoadingPage } from 'components/molecules/Loader'
import { Page, PageHeader } from 'components/organisms/layout'
import { MODALS } from 'components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { DATE_TIME_FORMAT } from 'utils/date'
import dayjs from 'dayjs'
import Table from 'components/organisms/Table'
import {
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
  Row,
  Column,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet, sortByDate } from 'utils/array'
import { BroadcastResponse } from '@types'
import Header from 'components/organisms/Table/atoms/Header'
import { highlight } from 'utils/highlight'
import { formatBroadcastData } from './utils'

export interface BroadcastWithMessage extends BroadcastResponse {
  users: string
  messageText: string
  channelName?: string
}

const columns: Column<BroadcastWithMessage>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.text')} />
    },
    accessor: 'messageText',
    sortType: (a, b) => sortByAlphabet(a.values.messageText, b.values.messageText),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
    width: 200,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.users')} />
    },
    accessor: 'users',
    sortType: (a, b) => sortByAlphabet(a.values.users, b.values.users),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
    width: 120,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.channel')} />
    },
    accessor: 'channelName',
    sortType: (a, b) => sortByAlphabet(a.values.channelName, b.values.channelName),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value!, globalFilter.search)} size="12" />
    ),
    width: 60,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.date')} />
    },
    accessor: 'created',
    sortType: (a, b) => sortByDate(a.values.created, b.values.created),
    Cell: ({ value }) => {
      if (!value) return <div>-</div>
      const date = dayjs(value).format(DATE_TIME_FORMAT)
      return <BlackParagraph text={date} size="12" />
    },
    width: 50,
  },

  {
    Header: () => null,
    accessor: 'id',
    disableSortBy: true,
    Cell: () => {
      return <div />
    },
    width: 0,
  },
]

const Broadcast: FC = () => {
  const [t] = useTranslation()

  const { data: broadcasts, isLoading } = useQuery(['broadcasts'], getBroadcastsFn, {
    refetchInterval: 10000,
    select: (data) => {
      const formattedData = formatBroadcastData(data)
      return formattedData
    },
  })

  const tableProps = useTable(
    {
      columns,
      data: broadcasts ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: { search: '' } },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  if (isLoading || !broadcasts) {
    return <LoadingPage />
  }

  return (
    <Page title={t('page.broadcast.title')}>
      <PageHeader
        title={t('page.broadcast.title')}
        subtitle={broadcasts?.length.toString()}
        actions={
          <>
            <LinkTo to={`/broadcast?modal=${MODALS.addChannel}`}>
              <Button icon={<Icon icon="uil:plus" />} appearance="outline" style={{ marginLeft: '12px' }}>
                {t('page.broadcast.addChannel')}
              </Button>
            </LinkTo>
            <LinkTo to={`/broadcast?modal=${MODALS.newBroadcast}`}>
              <Button icon={<Icon icon="uil:plus" />} appearance="blue" style={{ marginLeft: '12px' }}>
                {t('page.broadcast.newBroadcast')}
              </Button>
            </LinkTo>
          </>
        }
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

interface GlobalFilter {
  search: string
}

const globalFilterFunc = (
  rows: Row<BroadcastWithMessage>[],
  _columnIds: string[],
  filterValue: GlobalFilter
): Row<BroadcastWithMessage>[] => {
  let remainingRows = [...rows]

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { user_id, messageText, channelName } = r.original

      if (isIncludeFields([user_id, messageText, channelName], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default Broadcast
