////////////////////////////////////////////////////////////////////

import dayjs from "dayjs";

////////////////////////////////////////////////////////////////////
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getDateValueMessages(date: string) {
  const djsDate = dayjs(date, DATE_FORMAT);
  const today = dayjs();

  if (djsDate.isAfter(dayjs().subtract(1, "week"))) {
    if (djsDate.isSame(today, "day")) {
      return "Today";
    }

    if (djsDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    }

    // @ts-ignore
    return days[djsDate.weekday()];
  }

  return date;
}
////////////////////////////////////////////////////////////////////

export function getDateValueNotifications(date: string) {
  const djsDate = dayjs(date);
  const today = dayjs();

  if (djsDate.isAfter(dayjs().subtract(1, "week"))) {
    if (djsDate.isSame(today, "m")) {
      return "Just now";
    }

    if (djsDate.isSame(today, "day")) {
      return djsDate.format("HH:mm");
    }

    if (djsDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    }

    // @ts-ignore
    return days[djsDate.weekday()];
  }

  return dayjs().format(DATE_FORMAT);
}
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_TIME_FORMAT = "DD MMM YYYY - HH:mm";
