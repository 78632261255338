import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Icon from 'components/atoms/Icon'
import Button from 'components/molecules/Button'
import { useQuery, useQueryClient } from 'react-query'
import { createAutoconfigFn, getFeatureGroupsFn } from 'api'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { TextArea } from 'components/atoms/Input'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Realm } from '@types'

const AddUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const [qrSrc, setQr] = React.useState<string>()
  const queryClient = useQueryClient()

  const { data: featureGroupsOptions } = useQuery(['feature-groups'], getFeatureGroupsFn, {
    select: (data) => data.map(({ name, i_featuregroup }) => ({ label: name, value: i_featuregroup })),
  })

  const realms = queryClient.getQueryData('realms') as Realm[]

  const {
    control,
    formState: { isValid, isDirty, isSubmitSuccessful },
    handleSubmit,
    getValues,
    reset,
  } = useForm<
    Partial<{
      username: string
      realm: number
      featuregroup: number
      first_name: string
      last_name: string
      info: string
    }>
  >()

  const { username, featuregroup, first_name, last_name, info } = getValues()

  useQuery(
    ['create-autoconfig', username, realms?.[0].i_realm || 1, featuregroup, first_name, last_name, info],
    createAutoconfigFn,
    {
      enabled: isSubmitSuccessful,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: async (blob) => {
        const file = new File([blob], `qr${blob.size}`, {
          type: blob.type,
        })
        const qrCode = URL.createObjectURL(file)

        setQr(qrCode)
        queryClient.invalidateQueries(['users'])
      },
      onError: async (err: any) => {
        if (err.response?.data instanceof Blob) {
          const blobResponse = await err.response?.data?.text()
          const blobData = JSON.parse(blobResponse)
          let message = blobData?.reason
          toast.error(message)
        }
        reset()
      },
    }
  )

  return (
    <ModalView title={t('modal.addUser.add')} onClose={onClose}>
      <Content>
        {isSubmitSuccessful ? (
          <>
            {qrSrc ? (
              <img src={qrSrc} style={{ width: 300 }} alt="qr-code" />
            ) : (
              <div style={{ width: 300, height: 300, border: '1px solid' }}>
                <span>{t('modal.addUser.QRgenerating')}</span>
              </div>
            )}
            <DownloadForm href={qrSrc} download={`${username}.png`}>
              <Button icon={<Icon icon="uil:import" />} appearance="flat">
                {t('modal.addUser.QRdownload')}
              </Button>
            </DownloadForm>
          </>
        ) : (
          <Form onSubmit={handleSubmit(() => {})}>
            <Field>
              <Controller
                name="username"
                defaultValue=""
                rules={{ required: true, minLength: 3, maxLength: 24 }}
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.username')} {...props} />
                )}
              />
              <div>
                <GreyParagraph2
                  style={{ marginLeft: '15px', marginBottom: '5px' }}
                  size="11"
                  text={t('common.featureGroup')}
                />
                <Controller
                  name="featuregroup"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, value, ...props } }) => {
                    return (
                      <ReactSelect
                        value={featureGroupsOptions?.filter((g) => g.value === value)}
                        onChange={({ value }) => {
                          onChange(value)
                        }}
                        isSearchable={false}
                        options={featureGroupsOptions}
                        placeholder={t('placeholder.chooseFeatureGroup')}
                        {...props}
                      />
                    )
                  }}
                />
              </div>
            </Field>

            <Field>
              <Controller
                name="first_name"
                defaultValue=""
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.firstName')} {...props} />
                )}
              />
              <Controller
                name="last_name"
                defaultValue=""
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.lastName')} {...props} />
                )}
              />
            </Field>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.info')} />
              <Controller
                name="info"
                defaultValue=""
                control={control}
                render={({ field: { ref, value, ...props } }) => <TextArea rows={4} value={value} {...props} />}
              />
            </div>
            <Button
              style={{ alignSelf: 'flex-end', marginTop: '12px' }}
              appearance="blue"
              type="submit"
              disabled={!isDirty || !isValid}
            >
              {t('modal.addUser.add')}
            </Button>
          </Form>
        )}
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const DownloadForm = styled.a`
  display: contents;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    min-width: 180px;
  }
`

export default AddUser
