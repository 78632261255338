import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getCustomersFn, getFeatureGroupsFn, updateCustomerFn } from 'api'
import { Controller, useForm } from 'react-hook-form'
import { GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Loader from 'components/molecules/Loader'
import { useParams } from 'react-router-dom'

const EditUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const params = useParams<{ id: string }>()
  const customer_id = +params.id!
  const queryClient = useQueryClient()

  const { data: selectedCustomer } = useQuery(['customers'], getCustomersFn, {
    select: (data) => data.filter(({ i_customer }) => i_customer === customer_id)?.[0],
  })

  const { data: featureGroupsOptions } = useQuery(['feature-groups'], getFeatureGroupsFn, {
    select: (data) => data.map(({ name, i_featuregroup }) => ({ label: name, value: i_featuregroup })),
  })

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm<{
    featuregroup: number
  }>()

  const { mutate: updateCustomer } = useMutation(updateCustomerFn, {
    onSuccess: () => {
      onClose()
      queryClient.invalidateQueries(['users'])
      queryClient.invalidateQueries(['customers'])
    },
    onError: () => {
      toast.error(t('modal.editUser.editError'))
    },
  })

  const onSubmit = ({ featuregroup }: { featuregroup: number }) => {
    updateCustomer({
      i_customer: customer_id,
      i_featuregroup: featuregroup,
    })
  }

  if (!selectedCustomer) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.editUser.title')} style={{ maxWidth: '400px' }} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <GreyParagraph2
              style={{ marginLeft: '15px', marginBottom: '5px' }}
              size="11"
              text={t('common.featureGroup')}
            />
            <Controller
              name="featuregroup"
              defaultValue={selectedCustomer.i_featuregroup}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...props } }) => {
                return (
                  <ReactSelect
                    value={featureGroupsOptions?.filter((g) => g.value === value)}
                    onChange={({ value }) => {
                      onChange(value)
                    }}
                    isSearchable={false}
                    options={featureGroupsOptions}
                    placeholder={t('placeholder.chooseFeatureGroup')}
                    {...props}
                  />
                )
              }}
            />
          </div>

          <Button
            style={{ alignSelf: 'flex-end', marginTop: '12px' }}
            appearance="blue"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            {t('modal.editUser.edit')}
          </Button>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

export default EditUser
