import React, { FC, useCallback } from 'react'
import Icon from 'components/atoms/Icon'
import { BlackParagraph } from 'components/atoms/Paragraph'
import Table from 'components/organisms/Table'
import Header from 'components/organisms/Table/atoms/Header'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet, sortByDate } from 'utils/array'
import { highlight } from 'utils/highlight'
import Tabs, { Tab } from 'components/molecules/Tabs'
import Button, { IconButton } from 'components/molecules/Button'
import { AppPlatform, AppVersion } from '@types'
import { getAppVersionsFn, updateAppVersionFn } from 'api'
import { Page, PageHeader } from 'components/organisms/layout'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from 'utils/date'
import { LinkTo } from 'components/atoms/Pointer'
import { MODALS } from 'components/organisms/Modal'
import { Switch } from 'components/molecules/Switch'
import { useConfirm } from 'components/organisms/ConfirmDialog'

const columns: Column<AppVersion>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.platform')} />
    },
    accessor: 'version_name',
    width: 80,
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, row, state: { globalFilter } }) => {
      const { platform } = row.original

      const getPlatformIcon = (platform: AppPlatform) => {
        switch (platform) {
          case 'ANDROID':
            return 'uil:android'
          case 'IOS':
            return 'uil:apple'
          case 'DESKTOP_LINUX':
            return 'uil:linux'
          case 'DESKTOP_WIN':
            return 'uil:windows'
          case 'DESKTOP_MAC':
            return 'uil:apple-alt'
          default:
            return 'uil:desktop'
        }
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon icon={getPlatformIcon(platform)} color="#565656" />
          <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
        </div>
      )
    },
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.arch')} />
    },
    accessor: 'arch',
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
    width: 80,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.date')} />
    },
    accessor: 'update_date',
    sortType: (a, b) => sortByDate(a.values.update_date, b.values.update_date),
    Cell: ({ value }) => {
      const date = value ? dayjs(value as string).format(DATE_TIME_FORMAT) : '-'
      return <BlackParagraph text={date} size="12" />
    },
    width: 80,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.enabled')} />
    },
    accessor: 'enabled',
    id: 'enabled',
    Cell: ({ row, value }) => {
      const [t] = useTranslation()
      const { confirm } = useConfirm()
      const queryClient = useQueryClient()
      const { id, version_code, version_name, arch, download_url, platform } = row.original

      const { mutate: updateApp } = useMutation(updateAppVersionFn, {
        onSuccess: (res) => {
          if (res) {
            queryClient.invalidateQueries(['app-versions'])
          }
        },
      })

      return (
        <Switch
          checked={value}
          onToggle={async () => {
            const isConfirmed = await confirm(
              <p style={{ width: '210px' }}>
                {value ? t('page.apps.reallyDisable') : t('page.apps.reallyEnable')}
                <b>{` ${version_name} (${version_code}) `}</b>?
              </p>,
              <Button appearance={value ? 'red' : 'outline'}>{value ? t('common.disable') : t('common.enable')}</Button>
            )
            if (isConfirmed) {
              updateApp({ id, enabled: !value, version_code, version_name, arch, download_url, platform })
            }
          }}
        />
      )
    },
    width: 30,
    disableSortBy: true,
  },
  {
    Header: () => null,
    accessor: 'download_url',
    id: 'edit',
    Cell: (cell) => {
      const { download_url } = cell.row.original

      return (
        <a href={download_url} target="_blank" rel="noreferrer">
          <IconButton>
            <Icon icon="uil:arrow-circle-down" />
          </IconButton>
        </a>
      )
    },
    width: 30,
    disableSortBy: true,
  },
]

const Apps: FC = () => {
  const [t] = useTranslation()

  const initialGlobalFilter = { search: '', os: 'all' }

  const { data: apps, isLoading } = useQuery(['app-versions'], getAppVersionsFn)

  const tableProps = useTable(
    {
      columns,
      data: apps ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: initialGlobalFilter },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  const switchOs = useCallback(
    (os: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, os })
    },
    [globalFilter, tableProps]
  )

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
      tableProps.gotoPage(0)
    },
    [globalFilter, tableProps]
  )

  return (
    <Page title={t('page.apps.title')}>
      <PageHeader
        title={t('page.apps.title')}
        subtitle={tableProps?.rows?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
        actions={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap-reverse',
              gap: '8px',
            }}
          >
            <Tabs activeTab={globalFilter.os} onSwitch={switchOs}>
              <Tab label={t('common.all')} value="all" />
              <Tab label={t('common.ios')} value="ios" />
              <Tab label={t('common.android')} value="android" />
              <Tab label={t('common.desktop')} value="desktop" />
            </Tabs>

            <div style={{ display: 'flex' }}>
              <LinkTo to={`/apps?modal=${MODALS.addApp}`}>
                <Button icon={<Icon icon="uil:plus" />}>{t('page.apps.add')}</Button>
              </LinkTo>
            </div>
          </div>
        }
      />
      <Table isLoading={isLoading} initialFilter={initialGlobalFilter} {...tableProps} />
    </Page>
  )
}

export interface GlobalFilter {
  search: string
  os: string
}

const globalFilterFunc = (
  rows: Row<AppVersion>[],
  _columnIds: string[],
  filterValue: GlobalFilter
): Row<AppVersion>[] => {
  let remainingRows = [...rows]

  remainingRows = remainingRows.filter((r) => {
    const { platform } = r.original
    if (filterValue.os === 'desktop') {
      return platform.includes('DESKTOP')
    }

    return filterValue.os === 'all' ? true : filterValue.os === platform.toLowerCase()
  })

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { platform, version_name } = r.original

      if (isIncludeFields([platform, version_name], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default Apps
