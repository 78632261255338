import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { AdminUserCredentials } from '@types'
import { toast } from 'react-toastify'
import { createAdminFn } from 'api'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'

const FormField = ({ children, error }: { children: ReactNode; error?: string }) => (
  <div style={{ position: 'relative' }}>
    {children}
    <span style={{ position: 'absolute', bottom: -16, right: 10, fontSize: '12px', color: '#d06d6e' }}>{error}</span>
  </div>
)

const adminTypesOptions = [{ label: 'Admin', value: 'ADMINISTRATOR' }]

const passRegExp = new RegExp('(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z]).{10}')

const schema = z
  .object({
    username: z.string().min(1, { message: 'Required' }),
    type: z.string().startsWith('ADMINISTRATOR', { message: 'Choose admin type' }),
    password: z.string().regex(passRegExp, {
      message: 'Min 10 chars, uppercase, lowercase and digit',
    }),
    password_confirmation: z.string().regex(passRegExp, {
      message: 'Min 10 chars, uppercase, lowercase and digit',
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const AddUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    setError,
  } = useForm<AdminUserCredentials>({ resolver: zodResolver(schema) })

  const { mutate: createAdmin } = useMutation((credentials: AdminUserCredentials) => createAdminFn(credentials), {
    onSuccess: ({ username, type }) => {
      queryClient.invalidateQueries('admins')
      toast.success(`${type} ${username} successfully created!`)
      onClose()
    },
    onError: () => {
      setError('password', { type: 'validate', message: 'Min 10 chars, uppercase, lowercase and digit' })
    },
  })

  const onSubmit = (credentials: AdminUserCredentials) => {
    createAdmin(credentials)
  }

  return (
    <ModalView title={t('modal.addAdmin.add')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="username"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions type="text" caption={t('common.username')} {...props} />
                </FormField>
              )}
            />
            <div>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.type')} />
              <Controller
                name="type"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <FormField error={errors[name]?.message}>
                      <ReactSelect
                        value={adminTypesOptions.filter((g) => g.value === value)}
                        onChange={({ value }) => {
                          onChange(value)
                        }}
                        options={adminTypesOptions}
                        placeholder={t('modal.addAdmin.choose')}
                        {...props}
                      />
                    </FormField>
                  )
                }}
              />
            </div>
          </Field>

          <Field>
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions
                    autoComplete="new-password"
                    type="password"
                    caption={t('common.password')}
                    {...props}
                  />
                </FormField>
              )}
            />
            <Controller
              name="password_confirmation"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions type="password" caption={t('common.passwordConfirm')} {...props} />
                </FormField>
              )}
            />
          </Field>

          <Button style={{ alignSelf: 'flex-end' }} appearance="blue" type="submit" disabled={!isDirty}>
            {t('modal.addAdmin.add')}
          </Button>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 24px;
  gap: 12px;
  & > * {
    flex: 1;
  }
`

export default AddUser
