import React, { FC, memo, MouseEvent, useEffect } from 'react'
import { InfoMessage, SelectOption } from '@types'
import Icon from 'components/atoms/Icon'
import Input from 'components/atoms/Input'
import { BlackBoldParagraph, BlackParagraph } from 'components/atoms/Paragraph'
import Button, { CloseButton, IconButton } from 'components/molecules/Button'
import ReactSelect from 'components/molecules/ReactSelect'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { DATE_TIME_FORMAT } from 'utils/date'
import { Controller, useForm } from 'react-hook-form'
import { TextArea } from 'components/atoms/Input'
import { useTranslation } from 'react-i18next'
import { getPlatformLabel } from '../utils'
import { Checkbox } from 'components/molecules/Checkbox'
import Tooltip from 'components/atoms/Tooltip'
import { LinkText } from 'components/atoms/Headline'
import { useQuery } from 'react-query'
import { getUsersFn } from 'api'
import { sortByAlphabet } from 'utils/array'

interface EditFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  text?: string | number
  checkbox?: boolean
  isEditing?: boolean
  edit: React.ReactNode
}

const EditField = ({ title, text, checkbox, isEditing, edit, ...rest }: EditFieldProps) => (
  <div style={{ marginBottom: '8px' }} {...rest}>
    <BlackBoldParagraph text={title} />
    {isEditing ? <>{edit}</> : text ? <Typography>{text}</Typography> : <Checkbox checked={checkbox} />}
  </div>
)

interface Props extends InfoMessage {
  bundles?: {
    label: string
    value: string
  }[]
  isEditing?: boolean
  onEdit: (e: MouseEvent) => any
  onCancel: VoidFunction
  onDelete: VoidFunction
  onSave: (values: Partial<InfoMessage>) => void
}

const MessageCard: FC<Props> = ({
  bundles = [],
  title,
  text,
  type,
  clientbundleid,
  show_always,
  build_threshold,
  enabled,
  created,
  buttons: buttonsJson,
  isEditing,
  for_users,
  onEdit,
  onCancel,
  onSave,
  onDelete,
}) => {
  const [t] = useTranslation()
  const {
    control,
    reset,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm<
    Partial<
      InfoMessage & {
        button?: string
        buttonCancel?: string
        buttonUrl?: string
        buttonIsPrimary?: boolean
        user_ids?: string[]
      }
    >
  >()
  const buttons: [
    { type: 'openurl'; label: string; url: string; primary?: boolean },
    { type: 'cancel'; label: string }
  ] = buttonsJson && JSON.parse(buttonsJson)

  const isAlertMessage = type === 'alert'
  const isTargetedByUsers = Boolean(for_users?.length)

  const { data: usersOptions, isLoading: isUsersLoading } = useQuery(['users'], getUsersFn, {
    select: (data) => {
      return data
        .filter(({ last_login }) => !!last_login)
        .map(({ name }) => ({ value: name, label: name }))
        .sort((a, b) => sortByAlphabet(a.value, b.value))
    },
    enabled: isTargetedByUsers,
  })

  useEffect(() => {
    reset()
  }, [isEditing, reset])

  return (
    <MessageForm onSubmit={handleSubmit(onSave)}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tooltip message={`${t('common.type')}: ${type}`}>
          <Controller
            name="type"
            defaultValue={type}
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, value } }) =>
              value === 'alert' ? (
                <Icon icon="uil:info-circle" color="#bea800" />
              ) : (
                <Icon icon="uil:exit" color="#006f9c" />
              )
            }
          />
        </Tooltip>
        {isEditing ? (
          <div style={{ display: 'flex' }}>
            <CloseButton size="large" onClick={onCancel} />
            <IconButton size="large" disabled={!isValid} type="submit">
              <Icon icon="uil:save" />
            </IconButton>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <IconButton size="large" onClick={onDelete}>
              <Icon icon="uil:trash" color="#d06d6e" />
            </IconButton>
            <IconButton size="large" onClick={onEdit}>
              <Icon icon="uil:edit" />
            </IconButton>
          </div>
        )}
      </div>

      {isAlertMessage && (
        <EditField
          title={`${t('common.title')}:`}
          text={title}
          edit={
            <Controller
              name="title"
              defaultValue={title}
              rules={{ required: true }}
              control={control}
              render={({ field: { ref, value, ...props } }) => <StyledInput value={value} {...props} />}
            />
          }
          isEditing={isEditing}
        />
      )}
      <EditField
        title={isAlertMessage ? `${t('common.text')}:` : `${t('common.url')}:`}
        text={text}
        edit={
          <Controller
            name="text"
            defaultValue={text}
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, value, ...props } }) => <StyledTextArea rows={4} value={value} {...props} />}
          />
        }
        isEditing={isEditing}
      />

      <Block>
        {!isTargetedByUsers ? (
          <EditField
            title={`${t('common.bundle')}:`}
            text={getPlatformLabel(clientbundleid)}
            edit={
              <Controller
                name="clientbundleid"
                defaultValue={clientbundleid}
                control={control}
                render={({ field: { ref, value, onChange, ...props } }) => (
                  <StyledSelect
                    value={bundles?.filter((g) => g.value === value)}
                    onChange={({ value }) => {
                      onChange(value)
                    }}
                    options={bundles}
                    placeholder={t('placeholder.chooseBundle')}
                    {...props}
                  />
                )}
              />
            }
            isEditing={isEditing}
          />
        ) : (
          <div>
            <BlackBoldParagraph text={`${t('common.users')}:`} />
            {isEditing ? (
              <Controller
                defaultValue={for_users!.map(({ user }) => user.user_id)}
                name="user_ids"
                rules={{ required: true }}
                control={control}
                render={({ field: { ref, onChange, value, ...props } }) => {
                  return (
                    <div style={{ margin: '8px 0' }}>
                      <ReactSelect
                        value={value?.map((value) => ({ label: value, value }))}
                        placeholder={t('placeholder.chooseRecipients')}
                        onChange={(option: SelectOption[]) => {
                          const user_ids = option.map(({ value }) => value)
                          setValue('user_ids', user_ids)
                        }}
                        options={usersOptions}
                        isLoading={isUsersLoading}
                        isMulti
                        {...props}
                      />
                    </div>
                  )
                }}
              />
            ) : (
              <UserList>
                {for_users!.map(({ user }) => (
                  <span key={user.i_userid}>{user.user_id}</span>
                ))}
              </UserList>
            )}
          </div>
        )}
      </Block>

      {buttons && (
        <Block>
          <div style={{ marginBottom: '8px' }}>
            <Controller
              name="buttonUrl"
              defaultValue={buttons[0].url}
              control={control}
              render={({ field: { value } }) => (
                <a href={value} target="_blank" rel="noreferrer">
                  <LinkText>{value}</LinkText>
                </a>
              )}
            />

            <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
              <Controller
                name="button"
                defaultValue={buttons[0].label}
                control={control}
                render={({ field: { value } }) => (
                  <Button appearance="outline" disabled>
                    {value}
                  </Button>
                )}
              />
              <Controller
                name="buttonCancel"
                defaultValue={buttons[1].label}
                control={control}
                render={({ field: { value } }) => (
                  <Button appearance="outline" disabled>
                    {value}
                  </Button>
                )}
              />
            </div>
            <EditField
              style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '8px' }}
              title={`${t('modal.newInfoMessage.primary')}`}
              checkbox={buttons[0].primary}
              edit={
                <Controller
                  name="buttonIsPrimary"
                  defaultValue={buttons[0].primary}
                  control={control}
                  render={({ field: { ref, value, onChange, ...rest } }) => (
                    <Checkbox checked={value} onToggle={onChange} {...rest} />
                  )}
                />
              }
              isEditing={isEditing}
            />
          </div>
        </Block>
      )}

      <Block>
        {build_threshold !== -1 ? (
          <EditField
            title={`${t('common.build')}:`}
            text={build_threshold}
            edit={
              <Controller
                name="build_threshold"
                defaultValue={build_threshold}
                control={control}
                render={({ field: { ref, value, ...props } }) => <StyledInput value={value} {...props} />}
              />
            }
            isEditing={isEditing}
          />
        ) : (
          <div />
        )}
        <div style={{ display: 'flex', gap: '12px' }}>
          <EditField
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
            title={`${t('common.showAlways')}`}
            checkbox={show_always}
            edit={
              <Controller
                name="show_always"
                defaultValue={show_always}
                control={control}
                render={({ field: { ref, value, onChange, ...props } }) => (
                  <Checkbox
                    checked={value}
                    onToggle={(value) => {
                      onChange(value)
                    }}
                    {...props}
                  />
                )}
              />
            }
            isEditing={isEditing}
          />
          <EditField
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
            title={`${t('common.enabled')}`}
            checkbox={enabled}
            edit={
              <Controller
                name="enabled"
                defaultValue={enabled}
                control={control}
                render={({ field: { ref, value, onChange, ...props } }) => (
                  <Checkbox
                    checked={value}
                    onToggle={(value) => {
                      onChange(value)
                    }}
                    {...props}
                  />
                )}
              />
            }
            isEditing={isEditing}
          />
        </div>
      </Block>

      <MessageCreated text={`from ${dayjs(created).format(DATE_TIME_FORMAT)}`} />
    </MessageForm>
  )
}

const MessageForm = styled.form`
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  padding: 32px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.32);
  width: 100%;
  max-width: 1000px;
`

const Block = styled.div`
  display: flex;
  justify-content: space-between;
`

const UserList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  gap: 8px;
  > span {
    font-size: 12px;
    color: #3e3e3e;
    padding-right: 8px;
    &:not(:last-child) {
      border-right: 1px solid #ccc;
    }
  }
`

const MessageCreated = styled(BlackParagraph)`
  position: absolute;
  font-size: 12px;
  right: 30px;
  bottom: 12px;
  color: #999999;
`

const Typography = styled.span`
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.black};
`

const StyledInput = styled(Input)`
  width: 300px;
  height: 48px;
  margin: 4px 0;
`
const StyledTextArea = styled(TextArea)`
  width: 100%;
  margin-top: 8px;
`
const StyledSelect = styled(ReactSelect)`
  width: 300px;
  margin: 4px 0;
`

export default memo(MessageCard)
