import React, { useCallback } from 'react'
import { BlackParagraph } from 'components/atoms/Paragraph'
import Header from 'components/organisms/Table/atoms/Header'
import { useTranslation } from 'react-i18next'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet, sortByDate } from 'utils/array'
import { highlight } from 'utils/highlight'
import Icon from 'components/atoms/Icon'
import { LinkTo } from 'components/atoms/Pointer'
import { IconButton } from 'components/molecules/Button'
import { Dropdown } from 'components/molecules/Dropdown'
import { MenuItem, Popup } from 'components/molecules/Popup'
import Table from 'components/organisms/Table'
import { MODALS } from 'components/organisms/Modal'
import { useContacts } from './useContacts'
import { Contact } from '@types'
import { useQuery } from 'react-query'
import { getContactsExportCsvFn } from 'api'
import DotAndText from 'components/atoms/DotAndText'
import { Page, PageHeader } from 'components/organisms/layout'

const columns: Column<Contact>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.lastName')} />
    },
    accessor: 'lastname',
    sortType: (a, b) => sortByAlphabet(a.values.lastname, b.values.lastname),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.firstName')} />
    },
    accessor: 'firstname',
    sortType: (a, b) => sortByAlphabet(a.values.firstname, b.values.firstname),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.info')} />
    },
    accessor: 'info',
    sortType: (a, b) => sortByAlphabet(a.values.info, b.values.info),
    Cell: ({ value, state: { globalFilter } }) => <BlackParagraph text={highlight(value, globalFilter.search)} />,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.identity')} />
    },
    accessor: 'identity',
    sortType: (a, b) => sortByAlphabet(a.values.identity, b.values.identity),
    Cell: ({ value, state: { globalFilter } }) => {
      return <BlackParagraph text={highlight(value, globalFilter.search)} />
    },
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.visibility')} />
    },
    accessor: 'visible',
    sortType: (a, b) => sortByDate(a.values.visible, b.values.visible),
    Cell: ({ value }) => {
      const [t] = useTranslation()

      return (
        <DotAndText text={value ? t('common.visible') : t('common.hidden')} color={value ? '#499976' : '#d06d6e'} />
      )
    },
    width: 100,
  },
  {
    Header: () => null,
    accessor: 'firstname',
    id: 'edit',
    Cell: ({ cell }) => {
      const [t] = useTranslation()
      const { id } = cell.row.original

      return (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            <LinkTo to={`/contacts/${id}?modal=${MODALS.editContact}`}>
              <MenuItem value="edit" icon={<Icon icon="uil:edit" />} onClick={() => {}}>
                {t('common.edit')}
              </MenuItem>
            </LinkTo>
          </Popup>
        </Dropdown>
      )
    },
    width: 30,
    disableSortBy: true,
  },
]

export default function Contacts() {
  const [t] = useTranslation()
  const { data: contacts, isLoading } = useContacts()
  const initialGlobalFilter = { status: [], feature_profile: [], search: '' }

  const tableProps = useTable(
    {
      columns,
      data: contacts ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: initialGlobalFilter }, // Pass our hoisted table state
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter = tableProps.state.globalFilter as GlobalFilter

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  const { refetch: exportCsv } = useQuery(['contacts-csv'], getContactsExportCsvFn, {
    enabled: false,
  })

  return (
    <Page title={t('page.contacts.title')}>
      <PageHeader
        title={t('page.contacts.title')}
        subtitle={contacts?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
        onExportCsv={exportCsv}
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

interface GlobalFilter {
  search: string
  status: string[]
  feature_profile: string[]
}

const globalFilterFunc = (rows: Row<Contact>[], columnIds: string[], filterValue: GlobalFilter): Row<Contact>[] => {
  let remainingRows = [...rows]
  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { firstname, lastname, info, identity } = r.original
      if (isIncludeFields([firstname, lastname, info, identity], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}
