import { Realm } from '@types'

export enum Roles {
  Admin = 'ADMINISTRATOR',
  SuperAdmin = 'SUPERADMIN',
}

export const FakeRealm:Realm = {
  name: 'ALL',
  domain: 'ALL',
  autoconfig_endpoint: null,
  debug_css: 0,
  cert: null,
  default_realm: 0,
  xmpp_fallback: '',
  i_realm: -1,
  css_base_url: '',
  disable_ads: -1,
  i_featuregroup: 0,
  i_sig_key: 0,
  featuregroup_pro: 0
}