export const downloadFile = (blob: Blob, filename: string) => {
  const href = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()

  if (typeof link.download === 'undefined') {
    link.setAttribute('target', '_blank')
  }

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
