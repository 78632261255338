import {
  LoginRequest,
  LoginResponse,
  Realm,
  User,
  Contact,
  BundleMapping,
  InfoMessage,
  FeatureGroup,
  ActivityLog,
  AdminUser,
  AdminUserCredentials,
  RefreshRequest,
  Broadcast,
  BroadcastResponse,
  BroadcastChannel,
  Device,
  Customer,
  AppVersion,
  AppVersionRequest,
} from '@types'
import { downloadFile } from 'utils/download'
import api from '.'

export const loginUserFn = async (user: LoginRequest) => {
  const response = await api.post<LoginResponse>('auth/login', user)
  return response.data
}

export const refreshUserFn = async (data: RefreshRequest) => {
  const response = await api.post<LoginResponse>('auth/refresh', data)
  return response.data
}

export const logoutUserFn = async () => {
  const response = await api.post<{ ack?: 'ok' }>('auth/logout')
  return response.data
}

export const getMeFn = async () => {
  const response = await api.get<AdminUser>('users/me')
  return response.data
}

export const createAdminFn = async (credentials: AdminUserCredentials) => {
  const response = await api.post<AdminUser>('users/admin', credentials)
  return response.data
}

export const getAdminsFn = async () => {
  const response = await api.get<AdminUser[]>('users/admins')
  return response.data
}

export const getRealmsFn = async () => {
  const response = await api.get<Realm[]>('realms')
  return response.data
}

export const createRealmFn = async ({i_realm, ...data}: Partial<Realm>) => {
  const response = await api.post<Realm>(`realms/`, data)
  return response.data
}

export const updateRealmFn = async ({ i_realm, ...data }: Partial<Realm>) => {
  const response = await api.put<Realm>(`realms/${i_realm}`, data)
  return response.data
}

export const getBundleIdFn = async () => {
  const response = await api.get<BundleMapping[]>('bundle_mappings')
  return response.data
}

export const getFeatureGroupsFn = async () => {
  const response = await api.get<FeatureGroup[]>('feature_groups')
  return response.data
}

export const createFeatureGroupsFn = async (data: Partial<FeatureGroup>) => {
  const response = await api.post<FeatureGroup[]>('feature_groups', data)
  return response.data
}

export const editFeatureGroupsFn = async ({ i_featuregroup, ...data }: Partial<FeatureGroup>) => {
  const response = await api.put<FeatureGroup[]>(`feature_groups/${i_featuregroup}`, data)
  return response.data
}

export const deleteFeatureGroupFn = async (id: number) => {
  return await api.delete<FeatureGroup[]>(`feature_groups/${id}`)
}

export const createAutoconfigFn = async ({ queryKey }: { queryKey: (string | number | undefined)[] }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryName, user_id, realm_id, feature_group_id, first_name, last_name, info] = queryKey

  const response = await api.get('createautoconfig', {
    params: {
      user_id,
      ...(feature_group_id && {
        feature_group_id,
      }),
      ...(realm_id && {
        realm_id,
      }),
      ...(first_name && {
        first_name,
      }),
      ...(last_name && {
        last_name,
      }),
      ...(info && {
        info,
      }),
    },
    responseType: 'blob',
  })
  return response.data
}

export const getUsersFn = async () => {
  const response = await api.get<User[]>('reports/users/registered/')

  return response.data
}

export const getUsersExportCsvFn = async () => {
  const response = await api.get<Blob>('reports/users/registered/', {
    responseType: 'blob',
    params: {
      csv: true,
    },
  })
  downloadFile(response.data, 'users.csv')
}

export const deleteUserFn = async (username: string) => {
  await api.delete<{ ack?: 'ok' }>(`users/delete/${username}`)
}

export const getCustomersFn = async () => {
  const response = await api.get<Customer[]>(`customers`)
  return response.data
}

export const updateCustomerFn = async ({ i_customer: id, i_featuregroup, ...data }: Partial<Customer>) => {
  await api.put<Customer>(`customers/${id}`, { feature_group: i_featuregroup })
}

export const getDevicesFn = async () => {
  const response = await api.get<Device[]>('devices', {
    params: {
      limit: 10000,
    },
  })

  return response.data
}

export const getContactsFn = async () => {
  const response = await api.get<Contact[]>('reports/users/roster_contacts')
  return response.data
}

export const getContactsExportCsvFn = async () => {
  const response = await api.get<Blob>('reports/users/roster_contacts/', {
    responseType: 'blob',
    params: {
      csv: true,
    },
  })
  downloadFile(response.data, 'contacts.csv')
}

export const updateContactsFn = async (data: Partial<Contact>) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    const value = data[key as keyof Contact]
    if (value) {
      formData.append(key, value.toString())
    }
  })

  const response = await api.put<Contact>(`reports/users/roster_contacts/${data.id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export const getInfoMessagesFn = async () => {
  const response = await api.get<InfoMessage[]>('info_messages')
  return response.data
}

export const createInfoMessageFn = async (message: InfoMessage) => {
  const response = await api.post<InfoMessage>('info_messages', message)
  return response.data
}

export const sendPushMessageFn = async (push: { message: string; user_ids?: string[]; info_message_id?: number }) => {
  const response = await api.post<{
    errors: {
      [key: string]: string
    }
    sent: {}
  }>('send_push', push)
  return response.data
}

export const getBroadcastsFn = async () => {
  const response = await api.get<BroadcastResponse[]>('broadcast/', { params: { limit: 1000 } })
  return response.data
}

export const createBroadcastFn = async ({
  text,
  user_ids,
  channel,
}: {
  text: string
  user_ids: string[]
  channel: string
}) => {
  const response = await api.post<Broadcast>('broadcast', {
    user_ids,
    recipient_type: 'USERID',
    i_realm: 1,
    message: {
      i_broadcast_channel: +channel,
      sender_name: '',
      sender_address: '',
      body: text,
      language: 'en',
      message_type: 'text',
    },
    i_tag_list: 0,
    publishing_date: Date.now() / 1000 - 86400,
    enabled: 1,
  })
  return response.data
}

export const getBroadcastsChannelsFn = async () => {
  const response = await api.get<BroadcastChannel[]>('broadcast/channels')
  return response.data
}

export const createBroadcastsChannelFn = async ({ name, i_realm }: { name: string; i_realm: number }) => {
  const response = await api.post<BroadcastChannel>('broadcast/channels', {
    i_realm,
    channel_id: name,
    name: name,
    username: name,
    profile_pic_url: '',
    channel_type: 'ro',
    creator: 0,
  })
  return response.data
}

export const updateInfoMessageFn = async ({ id, ...data }: Partial<InfoMessage>) => {
  await api.put<InfoMessage>(`info_messages/${id}`, data)
}

export const deleteInfoMessageFn = async (id: number) => {
  return await api.delete<{ ack?: 'ok' }>(`info_messages/${id}`)
}

export const getBundleMappingsFn = async () => {
  const response = await api.get<BundleMapping[]>('bundle_mappings')
  return response.data
}

export const getActivityLogFn = async () => {
  const response = await api.get<ActivityLog[]>('reports/activity', { params: { limit: 10000 } })
  return response.data
}

export const getAppVersionsFn = async () => {
  const response = await api.get<AppVersion[]>('application_versions/')
  return response.data
}

export const createAppVersionFn = async (data: AppVersionRequest) => {
  const response = await api.post<AppVersion>('application_versions/', data)
  return response.data
}

export const updateAppVersionFn = async ({ id, ...data }: Partial<AppVersion>) => {
  const response = await api.put<AppVersion>(`application_versions/${id}`, data)
  return response.data
}
