import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { createInfoMessageFn, getBundleMappingsFn, getUsersFn, sendPushMessageFn } from 'api'
import { Controller, useForm } from 'react-hook-form'
import { InfoMessage, SelectOption } from '@types'
import { Switch } from 'components/molecules/Switch'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { BlackBoldParagraph, BoldParagraph, GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { toast } from 'react-toastify'
import { ButtonType, infoMessageTypesOptions } from '..'
import Input, { TextArea } from 'components/atoms/Input'
import { useTranslation } from 'react-i18next'
import { getPlatformLabel } from '../utils'
import { Checkbox } from 'components/molecules/Checkbox'
import Tabs, { Tab } from 'components/molecules/Tabs'
import { sortByAlphabet } from 'utils/array'
import { useUserContext } from 'contexts/UserContext'

type TargetOptions = 'platform' | 'users' | 'raw'

const NewInfoMessage: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [targetBy, setTargetBy] = useState<TargetOptions>('platform')
  const isTargetByPlatform = targetBy === 'platform'

  const { getRealm } = useUserContext()

  const [type, setType] = useState(infoMessageTypesOptions[0].value)
  const [alertButtons, setAlertButtons] = useState<{
    isActive: boolean
    type?: ButtonType
    label?: string
    url?: string
  }>({ isActive: false })

  const [withPush, setWithPush] = useState(false)
  const [pushMessage, setPushMessage] = useState('')

  const { data: bundles } = useQuery(['bundle-mappings'], getBundleMappingsFn, {
    select: (data) =>
      data.map(({ clientbundleid }) => ({ label: getPlatformLabel(clientbundleid), value: clientbundleid })),
  })

  const { data: usersOptions, isLoading: isUsersLoading } = useQuery(['users'], getUsersFn, {
    select: (data) => {
      return data
        .filter(({ last_login }) => !!last_login)
        .map(({ name }) => ({ value: name, label: name }))
        .sort((a, b) => sortByAlphabet(a.value, b.value))
    },
    enabled: !isTargetByPlatform,
  })

  const isAlertMessage = type === 'alert'

  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
    unregister,
    setValue,
  } = useForm<
    Partial<
      InfoMessage & {
        button?: string
        buttonCancel?: string
        buttonUrl?: string
        buttonIsPrimary?: boolean
        user_ids?: string[]
      }
    >
  >()

  const { mutate: createInfoMessage } = useMutation(createInfoMessageFn, {
    onError: () => {
      toast.error(t('modal.newInfoMessage.createError'))
    },
    onSuccess: ({ id }) => {
      if (withPush && pushMessage) {
        sendPush({
          message: pushMessage,
          info_message_id: id,
        })
      }

      queryClient.invalidateQueries(['info-messages'])
      onClose()
    },
  })

  const { mutate: sendPush } = useMutation(sendPushMessageFn, {
    onSuccess: () => {
      toast.success(t('modal.newInfoMessage.createPushSuccess'))
    },
    onError: () => {
      toast.error(t('modal.newInfoMessage.createPushError'))
    },
  })

  const onChangeTarget = useCallback((targetBy: TargetOptions) => {
    setTargetBy(targetBy)
  }, [])

  const onSubmit = (values: any) => {
    let user_ids
    let isRawUsersValid

    if (targetBy === 'raw') {
      user_ids = (values.user_ids as string).split('\n').map((userId) => userId.trim())

      const invalidUsers = user_ids.filter(
        (userId) => !(usersOptions as SelectOption[]).some(({ value }) => value === userId)
      )

      isRawUsersValid = !invalidUsers.length
      if (!isRawUsersValid) {
        toast.warning(t('toast.nonValidUsers', { users: invalidUsers.join(', ') }))
      }
    }

    if (targetBy === 'users') {
      user_ids = values.user_ids
    }

    if (targetBy !== 'raw' || isRawUsersValid) {
      createInfoMessage({
        ...values,
        i_realm: getRealm().i_realm,
        build_threshold: isTargetByPlatform ? values.build_threshold : -1,
        user_ids: isTargetByPlatform ? undefined : user_ids,
        clientbundleid: isTargetByPlatform ? values.clientbundleid : '',
        buttons:
          isAlertMessage && alertButtons.isActive
            ? JSON.stringify([
                { type: 'openurl', label: values.button, url: values.buttonUrl, primary: values.buttonIsPrimary },
                { type: 'cancel', label: values.buttonCancel },
              ])
            : '',
      })
    }
  }

  useEffect(() => {
    if (!isAlertMessage) {
      unregister(['title', 'button', 'buttonUrl', 'buttonCancel', 'buttonIsPrimary'])
    }
  }, [isAlertMessage, unregister])

  return (
    <ModalView
      title={t('modal.newInfoMessage.title')}
      style={{ maxWidth: '900px', overflow: 'auto' }}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              marginBottom: '6px',
              flex: 1,
            }}
          >
            <Field>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '10px' }} size="11" text={t('common.type')} />
              <Controller
                name="type"
                rules={{ required: true }}
                defaultValue={infoMessageTypesOptions[0].value}
                control={control}
                render={({ field: { ref, onChange, value, ...props } }) => {
                  return (
                    <ReactSelect
                      value={{ value: value, label: value }}
                      onChange={({ value }) => {
                        onChange(value)
                        setType(value)
                      }}
                      options={infoMessageTypesOptions}
                      placeholder={t('placeholder.chooseMessageType')}
                      {...props}
                    />
                  )
                }}
              />
            </Field>
            <Field>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <CheckField>
                  <BlackBoldParagraph text={t('common.showAlways')} />
                  <Controller
                    name="show_always"
                    defaultValue={false}
                    control={control}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                      <Checkbox checked={value} onToggle={onChange} {...rest} />
                    )}
                  />
                </CheckField>
                <CheckField>
                  <BlackBoldParagraph text={t('common.enabled')} />
                  <Controller
                    name="enabled"
                    defaultValue={true}
                    control={control}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                      <Checkbox checked={value} onToggle={(v) => onChange(v)} {...rest} />
                    )}
                  />
                </CheckField>
              </div>
            </Field>
          </div>
          {isAlertMessage && (
            <Row>
              <Controller
                name="title"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <InputWithCaptions type="text" caption={t('common.title')} {...props} />
                )}
              />
            </Row>
          )}
          <Row>
            <Controller
              name="text"
              defaultValue=""
              rules={{ required: true }}
              control={control}
              render={({ field: { ref, ...props } }) => (
                <div>
                  <GreyParagraph2
                    style={{ marginLeft: '15px' }}
                    size="11"
                    text={isAlertMessage ? t('common.text') : t('common.url')}
                  />
                  {isAlertMessage ? <StyledTextArea rows={2} {...props} /> : <Input {...props} />}
                </div>
              )}
            />
          </Row>
          {isAlertMessage && (
            <Row>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BoldParagraph
                  style={{ margin: '0 4px 4px 15px' }}
                  size="11"
                  text={t('modal.newInfoMessage.addButton')}
                />
                <Switch
                  checked={alertButtons.isActive}
                  onToggle={(toggle) => setAlertButtons((prev) => ({ ...prev, isActive: toggle! }))}
                />
              </div>
              {alertButtons.isActive && (
                <div>
                  <div style={{ display: 'flex', gap: '12px', marginBottom: '6px' }}>
                    <Field>
                      <Controller
                        name="button"
                        defaultValue=""
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...props } }) => (
                          <InputWithCaptions
                            caption={t('modal.newInfoMessage.addButton.title')}
                            type="text"
                            {...props}
                          />
                        )}
                      />
                    </Field>
                    <Field>
                      <Controller
                        name="buttonCancel"
                        defaultValue=""
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { ref, ...props } }) => (
                          <InputWithCaptions
                            caption={t('modal.newInfoMessage.addButton.cancel')}
                            type="text"
                            {...props}
                          />
                        )}
                      />
                    </Field>
                  </div>
                  <Controller
                    name="buttonUrl"
                    defaultValue=""
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { ref, ...props } }) => (
                      <InputWithCaptions caption={t('modal.newInfoMessage.addButton.url')} type="text" {...props} />
                    )}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                    <Controller
                      name="buttonIsPrimary"
                      defaultValue={false}
                      control={control}
                      render={({ field: { ref, value, onChange, ...rest } }) => (
                        <Checkbox checked={value} onToggle={onChange} {...rest} />
                      )}
                    />
                    <BoldParagraph style={{ marginLeft: '15px' }} size="11" text={t('modal.newInfoMessage.primary')} />
                  </div>
                </div>
              )}
            </Row>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <BoldParagraph style={{ marginLeft: '15px', marginBottom: '10px' }} size="11" text={t('common.targetBy')} />
            <Tabs style={{ marginBottom: '9px' }} activeTab={targetBy} onSwitch={onChangeTarget}>
              <Tab label={t('common.bundle')} value="platform" />
              <Tab label={t('common.users')} value="users" />
              <Tab label={t('Raw')} value="raw" />
            </Tabs>

            <div
              style={{
                display: 'flex',
                gap: '12px',
                marginBottom: '16px',
                maxWidth: '400px',
                width: '50vw',
              }}
            >
              {isTargetByPlatform ? (
                <>
                  <Field>
                    <Controller
                      name="build_threshold"
                      defaultValue=""
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { ref, ...props } }) => (
                        <InputWithCaptions
                          caption={t('common.build')}
                          tooltip={t('tooltip.build')}
                          placeholder={t('placeholder.buildFormat')!}
                          type="text"
                          {...props}
                        />
                      )}
                    />
                  </Field>
                  {bundles && (
                    <Field>
                      <GreyParagraph2
                        style={{ marginLeft: '15px', marginBottom: '10px' }}
                        size="11"
                        text={t('common.bundle')}
                      />
                      <Controller
                        name="clientbundleid"
                        rules={{ required: true }}
                        defaultValue={bundles[0].value}
                        control={control}
                        render={({ field: { ref, onChange, value, ...props } }) => {
                          return (
                            <ReactSelect
                              value={bundles?.filter((g) => g.value === value)}
                              onChange={({ value }) => {
                                onChange(value)
                              }}
                              options={bundles}
                              isSearchable={false}
                              placeholder={t('placeholder.chooseBundle')}
                              {...props}
                            />
                          )
                        }}
                      />
                    </Field>
                  )}
                </>
              ) : (
                <>
                  <Field>
                    <GreyParagraph2
                      style={{ marginLeft: '15px', marginBottom: '10px' }}
                      size="11"
                      text={t('common.recipients')}
                    />
                    <Controller
                      name="user_ids"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { ref, onChange, value, ...props } }) => {
                        if (targetBy === 'raw') {
                          return <StyledTextArea rows={7} onChange={onChange} {...props} />
                        }
                        return (
                          <ReactSelect
                            value={value?.map((value) => ({ label: value, value }))}
                            placeholder={t('placeholder.chooseRecipients')}
                            onChange={(option: SelectOption[]) => {
                              const user_ids = option.map(({ value }) => value)
                              setValue('user_ids', user_ids)
                            }}
                            options={usersOptions}
                            isLoading={isUsersLoading}
                            isMulti
                            {...props}
                          />
                        )
                      }}
                    />
                  </Field>
                </>
              )}
            </div>
            {withPush && (
              <Row>
                <div>
                  <GreyParagraph2 style={{ marginLeft: '15px' }} size="11" text={t('modal.newInfoMessage.pushText')} />
                  <StyledTextArea rows={2} value={pushMessage} onChange={(e) => setPushMessage(e.target.value)} />
                </div>
              </Row>
            )}
            <Row>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={withPush} onToggle={() => setWithPush((prev) => !prev)} />
                <BoldParagraph
                  style={{ margin: '0 4px 4px 15px' }}
                  size="11"
                  text={t('modal.newInfoMessage.withPush')}
                />
              </div>
            </Row>
          </div>
          <Button style={{ alignSelf: 'end' }} appearance="blue" type="submit" disabled={!isValid || isSubmitting}>
            {t('common.send')}
          </Button>
        </div>
      </Form>
    </ModalView>
  )
}

const Form = styled.form`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 16px;
`
const Row = styled.div`
  margin-bottom: 16px;
`

const CheckField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 64px;
`

const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

const StyledTextArea = styled(TextArea)`
  width: 100%;
`

export default NewInfoMessage
