import { AdminUser, Realm } from '@types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { createContext, useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { FakeRealm } from 'utils/consts'

type State = {
  admin: AdminUser | null
  realm: Realm
}

type Dispatch = (action: Action) => void

type Action = {
  type: string
  payload: AdminUser | Realm | null
}

interface UserContextValue {
  token?: string
  refreshToken?: string
  setToken: (token: string) => void
  setRefreshToken: (token: string) => void
  getRealm: () => Realm
  isSuperAdmin: () => boolean
  state: State
  dispatch: Dispatch
}

type UserContextProviderProps = { children: React.ReactNode }

const initialContextValue = {
  setToken() {},
  setRefreshToken() {},
  dispatch() {},
  getRealm() { return FakeRealm },
  isSuperAdmin: () => false,
  state: {
    admin: null,
    realm: FakeRealm,
  },
}

export const UserContext = createContext<UserContextValue>(initialContextValue)

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [token, setToken] = useLocalStorage('token')
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token')
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const queryClient = useQueryClient()

  const getRealm = () => {
    if (state.realm && state.realm.i_realm !== -1) {
      return state.realm
    }
    const realms = queryClient.getQueryData('realms') as Realm[] || []
    return realms.filter((realm) => realm.default_realm > 0)[0] || ( realms[0] || FakeRealm )
  }

  const values = {
    state,
    dispatch,
    token,
    setToken,
    refreshToken,
    setRefreshToken,
    getRealm,
    isSuperAdmin: () => state.admin?.type === 'SUPERADMIN'
  }
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>
}

const initialState: State = {
  admin: null,
  realm: FakeRealm,
}

const stateReducer = (state: State, action: Action) : State => {
  switch (action.type) {
    case 'SET_ADMIN': {
      return {
        ...state,
        admin: action.payload as AdminUser | null,
      }
    }
    case 'SET_REALM': {
      return {
        ...state,
        realm: !action.payload ? FakeRealm : action.payload as Realm
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

export const useUserContext = () => {
  const context = React.useContext(UserContext)

  if (context) {
    return context
  }

  throw new Error(`useUserContext must be used within a UserContextProvider`)
}
