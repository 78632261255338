import React from 'react'
import Icon from 'components/atoms/Icon'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { IconButton } from 'components/molecules/Button'
import styled from 'styled-components'

interface Props {
  gotoPage(page: number): void
  previousPage(): void
  nextPage(): void
  canPreviousPage: boolean
  canNextPage: boolean
  pageIndex: number
  pageOptions: { length: number }
}

export default function Pagination({
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  gotoPage,
  previousPage,
  nextPage,
}: Props) {
  const [open, setOpen] = React.useState(false)

  return (
    <Container>
      <IconButton onClick={() => previousPage()}>
        <Icon icon="uil:arrow-left" color={canPreviousPage ? 'inherit' : '#ccc'} />
      </IconButton>
      <PageNumber pageCount={pageOptions.length} pageIndex={pageIndex} />
      <ArrowButton onClick={() => setOpen(!open)}>
        {open ? <Icon icon="uil:angle-up" /> : <Icon icon="uil:angle-down" />}
      </ArrowButton>
      <IconButton onClick={canNextPage ? () => nextPage() : undefined}>
        <Icon icon="uil:arrow-right" color={canNextPage ? 'inherit' : '#ccc'} />
      </IconButton>

      {open && (
        <PagesContainer>
          <ListView>
            {Array.from({ length: pageOptions.length }, (v, k) => k).map((i) => {
              return (
                <PageItem
                  active={i === pageIndex}
                  key={i}
                  onClick={() => {
                    setOpen(false)
                    return gotoPage(i)
                  }}
                >
                  <PageNumber pageCount={pageOptions.length} pageIndex={i} />
                </PageItem>
              )
            })}
          </ListView>
          <ArrowDownTriangle />
        </PagesContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: inline-grid;
  grid-template-columns: 40px 1fr 20px 40px;
  grid-column-gap: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
  padding: 4px 0;
`

const Span = styled.span`
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #b9b9b9;
`

const PageNumberContainer = styled.div`
  display: flex;
`

const ArrowButton = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`

const PagesContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const ListView = styled.div`
  background-color: white;
  box-shadow: 0 5px 15px rgba(167, 169, 189, 0.45);
  border-radius: 4px;
  min-width: 125px;
  max-height: 210px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

function PageNumber({ pageIndex, pageCount }: { pageIndex: number; pageCount: number }) {
  return (
    <PageNumberContainer>
      <BlackParagraph size="14" text={`Page ${pageIndex + 1}`} />
      <Span>/{pageCount}</Span>
    </PageNumberContainer>
  )
}

const PageItem = styled.div<{ active: boolean }>`
  padding: 5px 8px;
  cursor: pointer;
  background-color: ${(p) => (p.active ? p.theme.colors.coolGrey1 : 'white')};
`

const ArrowDownTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid #fff;
  position: relative;
  left: -4px;
`
