import React from 'react'
import { BrowserRouter, useRoutes, Navigate } from 'react-router-dom'

import Login, { Logout } from 'pages/login'

import App from './App'
import Users from 'pages/users'
import Devices from 'pages/devices'
import Contacts from 'pages/contacts'
import Broadcast from 'pages/broadcast'
import InfoMessages from 'pages/info-messages'
import FeatureGroups from 'pages/feature-groups'
import Apps from 'pages/apps'
import ActivityLog from 'pages/activity-log'
import Admins from 'pages/admins'
import Realms from 'pages/realms'

import NotFound from 'components/organisms/NotFound'
import { AuthGuard, RoleGuard } from 'guards'
import { Roles } from 'utils/consts'


function AppRoutes() {
  return useRoutes([
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <App />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/users" replace />, index: true },
        {
          path: '/users/',
          element: <Users />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/devices',
          element: <Devices />,
        },
        {
          path: '/contacts/',
          element: <Contacts />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        { path: '/broadcast', element: <Broadcast /> },
        { path: '/info-messages/*', element: <InfoMessages /> },
        {
          path: '/feature-groups/',
          element: <FeatureGroups />,
          children: [
            {
              path: ':id',
            },
          ],
        },
        {
          path: '/apps/',
          element: <Apps />,
        },
        {
          path: '/log',
          element: <RoleGuard allowedRoles={[Roles.SuperAdmin, Roles.Admin]} />,
          children: [
            {
              path: '',
              element: <ActivityLog />,
            },
          ],
        },
        {
          path: '/admin',
          element: <RoleGuard allowedRoles={[Roles.SuperAdmin, Roles.Admin]} />,
          children: [
            {
              path: '',
              element: <Admins />,
            },
          ],
        },
        {
          path: '/realms',
          element: <RoleGuard allowedRoles={[Roles.SuperAdmin]} />,
          children: [
            {
              path: '',
              element: <Realms />,
            },
          ],
        },
        { path: '/logout', element: <Logout /> },
        { path: '*', element: <NotFound /> },
      ],
    },
  ])
}

export default function Router() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}
