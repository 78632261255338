import React, { FC } from 'react'
import styled from 'styled-components'
import InputField from 'components/molecules/InputField'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { deleteUserFn } from 'api'
import { useTranslation } from 'react-i18next'

const DeleteUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [username, setUsername] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)

  const { mutate: deleteUser } = useMutation(deleteUserFn, {
    onError: () => {
      toast.error(t('page.users.deleteError', { name: username }))
    },
    onSuccess: (a, b) => {
      queryClient.invalidateQueries(['users'])
      navigate(-1)
      if (b) {
        toast.success(t('page.users.deleteSuccess', { name: username }))
      }
    },
  })

  const handleSubmit = () => {
    setSubmitted(true)
    deleteUser(username)
  }

  return (
    <ModalView title={t('modal.deleteUser.title')} onClose={onClose}>
      <Content>
        <InputField
          placeholder={t('common.username')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          disabled={submitted}
        />
        <Button appearance="red" onClick={handleSubmit}>
          {t('common.delete')}
        </Button>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
`

export default DeleteUser
