import React, { FC } from 'react'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Realm } from '@types'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import styled from 'styled-components'
import Button from 'components/molecules/Button'
import { useMutation, useQueryClient } from 'react-query'
import { createRealmFn } from 'api'
import { FakeRealm } from 'utils/consts'


const AddRealm: FC<ModalProps> = ({ onClose }) => {
    const [t] = useTranslation()
    const queryClient = useQueryClient()

    const {
        control,
        formState: { isValid, isDirty },
        handleSubmit,
    } = useForm<Partial<Realm> & Record<string, any>>({
      defaultValues: FakeRealm
    })

    const { mutate: createRealm } = useMutation(
      (data: Partial<Realm>) => createRealmFn({ ...data }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('realms')
          onClose()
        },
      }
    )

    const onSubmit = (values: Partial<Realm>) => {
      createRealm(values)
    }

    return (
      <ModalView title={t('modal.addUser.add')} onClose={onClose}>
          <Content>
              <Form onSubmit={handleSubmit(onSubmit)}>
                  <Field>
                      <Controller
                        name="name"
                        defaultValue=""
                        control={control}
                        render={({ field: { ref, ...props } }) => (
                          <InputWithCaptions type="text" caption={t('common.name')} {...props} />
                        )}
                      />
                      <Controller
                        name="domain"
                        defaultValue=""
                        control={control}
                        render={({ field: { ref, ...props } }) => (
                          <InputWithCaptions type="text" caption={t('realm.domain')} {...props} />
                        )}
                      />
                  </Field>
                  <Field>
                    <Controller
                      name="css_base_url"
                      defaultValue=""
                      control={control}
                      render={({ field: { ref, ...props } }) => (
                        <InputWithCaptions type="text" caption={t('realm.css_base_url')} {...props} />
                      )}
                    />
                    <Controller
                      name="xmpp_fallback"
                      defaultValue=""
                      control={control}
                      render={({ field: { ref, ...props } }) => (
                        <InputWithCaptions type="text" caption={t('realm.xmpp_fallback')} {...props} />
                      )}
                    />
                  </Field>
                <Field>
                  <Controller
                    name="pub_key"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...props } }) => (
                      <InputWithCaptions type="text" caption={t('realm.pub_key')} {...props} />
                    )}
                  />
                  <Controller
                    name="priv_key"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...props } }) => (
                      <InputWithCaptions type="text" caption={t('realm.priv_key')} {...props} />
                    )}
                  />
                </Field>
                <SubmitButton appearance="blue" type="submit" disabled={!isDirty || !isValid}>
                  {t('modal.addFeatureGroup.addGroup')}
                </SubmitButton>
              </Form>
          </Content>
      </ModalView>
    )
}

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-end;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    min-width: 180px;
  }
`


export default AddRealm